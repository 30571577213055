<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="인바운드 DashBoard"> </CarrotTitle>
    <div class="board">
      <div class="mt-20 mb-20">
        <CarrotDatePicker
          class="inline-datepicker float-left"
          v-model="dash.sdate"
        ></CarrotDatePicker>
        <span class=" float-left ml-10">~</span>
        <CarrotDatePicker
          class="inline-datepicker float-left ml-10"
          v-model="dash.edate"
        ></CarrotDatePicker>
        <button
            class="btn-default float-left ml-10"
            @click="dash.doSearch()"
          >
            검색
          </button>
        <button class="btn-default float-right" @click="dash.downExcel">DashBoard 다운로드</button>
        <div class="clear"></div>
      </div>
      <div class="container mb-100">
        <table border="0" cellspacing="0" cellpadding="0" class="table-row" align="center">
          <tbody>
            <tr class="border_b">
              <th>구분</th>
              <th width="30%">인바운드 개수</th>
              <th>{{dash.year}}년 누적</th>
              <td v-for="(irow, i) in dash.ymList" :key="i"> {{irow.ym_txt}}</td>
            </tr>

            <!-- 인바운드 처리 현황-->
            <tr>
              <th rowspan="5" class="border_b">인바운드<br/>처리 현황</th>
              <td class="fw_bold-bg_blue">총 요청 수</td>
              <td class="fw_bold-bg_blue">{{dash.inbound.total.TOTAL}}</td>
              <td class="fw_bold-bg_blue" v-for="(irow, i) in dash.inbound.list" :key="i">
                <span  v-if="irow.list.TOTAL"> {{ irow.list.TOTAL }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr>
              <td class="fc_red-fw-bold">오픈 확정 수</td>
              <td class="fc_red-fw-bold">{{dash.inbound.total.OPEN}}</td>
              <td class="fc_red-fw-bold" v-for="(irow, i) in dash.inbound.list" :key="i">
                <span v-if="irow.list.OPEN"> {{ irow.list.OPEN }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr>
              <td>처리 중</td>
              <td>{{dash.inbound.total.PROCESS}}</td>
              <td v-for="(irow, i) in dash.inbound.list" :key="i">
                <span  v-if="irow.list.PROCESS"> {{ irow.list.PROCESS }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr>
              <td>중단</td>
              <td>{{dash.inbound.total.STOP}}</td>
              <td v-for="(irow, i) in dash.inbound.list" :key="i">
                <span  v-if="irow.list.STOP"> {{ irow.list.STOP }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr>
              <td class="border_b">Fail</td>
              <td class="border_b">{{dash.inbound.total.FAIL}}</td>
              <td class="border_b" v-for="(irow, i) in dash.inbound.list" :key="i">
                <span v-if="irow.list.FAIL"> {{ irow.list.FAIL }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <!-- 인바운드 처리 현황 End -->

            <!-- 팔로업 배정 현황 Start-->
            <tr>
              <th :rowspan=" dash.sectors.length+1" class="border_b">팔로업<br/>배정 현황</th>
              <td class="fw_bold-bg_blue">총합</td>
              <td class="fw_bold-bg_blue">{{dash.fu.total.TOTAL}}</td>
              <td class="fw_bold-bg_blue" v-for="(irow, i) in dash.fu.list" :key="i">
                <span v-if="irow.list.TOTAL"> {{ irow.list.TOTAL }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr v-for="(irow, i) in dash.sectors" :key="i">
              <td :class="{ 'border_b' : i == dash.sectors.length-1 }">{{irow.deptname}} </td>
              <td :class="{ 'border_b' : i == dash.sectors.length-1 }">{{dash.fu.total[irow.deptname]}}</td>
              <td :class="{ 'border_b' : i == dash.sectors.length-1 }" v-for="(jrow,k) in dash.fu.list" :key="k">
                <span v-if="jrow.list[irow.deptname]"> {{ jrow.list[irow.deptname] }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <!-- 팔로업 배정 현황 End-->

            <!-- 과정 종류 Start -->
            <tr>
              <th :rowspan=" dash.program.length+1" class="border_b">과정 종류</th>
              <td class="fw_bold-bg_blue"> 총합 </td>
              <td class="fw_bold-bg_blue">{{dash.enterProgram.total.TOTAL}}</td>
              <td class="fw_bold-bg_blue" v-for="(irow, i) in dash.enterProgram.list" :key="i">
                <span v-if="irow.list.TOTAL"> {{ irow.list.TOTAL }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr v-for="(irow, i) in dash.program" :key="i">
              <td :class="{ 'border_b' : i == dash.program.length-1 }" v-html="irow.program"></td>
              <td :class="{ 'border_b' : i == dash.program.length-1 }">{{dash.enterProgram.total[irow.program]}}</td>
              <td :class="{ 'border_b' : i == dash.program.length-1 }" v-for="(jrow,k) in dash.enterProgram.list" :key="k">
                <span v-if="jrow.list[irow.program]"> {{ jrow.list[irow.program] }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <!-- 과정 종류 End -->

            <!-- 유입경로 Start-->
            <tr>
              <th :rowspan=" dash.path.length+1" class="border_b">유입경로</th>
              <td class="fw_bold-bg_blue"> 총합 </td>
              <td class="fw_bold-bg_blue">{{dash.enterPath.total.TOTAL}}</td>
              <td class="fw_bold-bg_blue" v-for="(irow, i) in dash.enterPath.list" :key="i">
                <span v-if="irow.list.TOTAL"> {{ irow.list.TOTAL }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr v-for="(irow, i) in dash.path" :key="i">
              <td :class="{ 'border_b' : i == dash.path.length-1 }" v-html="irow.path"></td>
              <td :class="{ 'border_b' : i == dash.path.length-1 }">{{dash.enterPath.total[irow.path]}}</td>
              <td :class="{ 'border_b' : i == dash.path.length-1 }" v-for="(jrow,k) in dash.enterPath.list" :key="k">
                <span v-if="jrow.list[irow.path]"> {{ jrow.list[irow.path] }} </span>
                <span v-else>0</span>
              </td>
            </tr>

            <tr>
              <th :rowspan=" dash.url.length+1" class="border_b">문의유입사이트</th>
              <td class="fw_bold-bg_blue"> 총합 </td>
              <td class="fw_bold-bg_blue">{{dash.enterUrl.total.TOTAL}}</td>
              <td class="fw_bold-bg_blue" v-for="(irow, i) in dash.enterUrl.list" :key="i">
                <span v-if="irow.list.TOTAL"> {{ irow.list.TOTAL }} </span>
                <span v-else>0</span>
              </td>
            </tr>
            <tr v-for="(irow, i) in dash.url" :key="i">
              <td :class="{ 'border_b' : i == dash.url.length-1 }" v-html="irow.url_text"></td>
              <td :class="{ 'border_b' : i == dash.url.length-1 }">{{dash.enterUrl.total[irow.url_text]}}</td>
              <td :class="{ 'border_b' : i == dash.url.length-1 }" v-for="(jrow,k) in dash.enterUrl.list" :key="k">
                <span v-if="jrow.list[irow.url_text]"> {{ jrow.list[irow.url_text] }} </span>
                <span v-else>0</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";

export default {
  layout: "customerManagement",
  components: {
    CarrotDatePicker,
  },
  setup() {
    const toast = useToast();

    const dash = reactive({
      isMounted: false,
      year:0, // 현재년도
      ymList:[], // 현재월
      sectors:[],
      path:[],
      url:[],
      program:[],
      sdate: '',
      edate: '',

      // REQUEST:처리요청, PROCESS:처리중, DONE:처리완료, OPEN:OPEN, FAIL:FAIL, STOP:중단, TOTAL:전체수
      inbound: { total: { REQUEST: 0,PROCESS: 0,DONE: 0,OPEN: 0,FAIL: 0,STOP: 0,TOTAL:0 } },

      fu: { total: { TOTAL: 0 } },

      enterPath:{total: { TOTAL: 0 }},
      enterUrl:{total: { TOTAL: 0 }},
      enterProgram:{total: { TOTAL: 0 }},
      downExcel : () => {
        let url = "https://api.carrotians.net";
        if ( window.location.host.includes("local")) {
          url = "http://local.intranet.mxm.kr";
        } else if(window.location.host.includes("stage")){
          url = "https://stageapi.carrotians.net";
        }
        url = url +"/excel/dashboard?sdate="+dash.sdate+"&edate="+dash.edate;
        window.open(url, '_blank').focus();
      },
      doSearch: () => {
        if(dash.sdate.substring(0,4) == dash.edate.substring(0,4)){
          let params = {
          sdate: dash.sdate,
          edate: dash.edate,
        };
        axios
          .get("/rest/customermgr/getInboundDashBoardList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              let dat =res.data.data;
              dash.year= dat.year;
              dash.ymList= dat.ym_list;

              //인바운드 처리 현황
              dash.inbound =dat.inbound;

              //팔로업 배정 현황
              dash.sectors= dat.sectors;
              dash.fu =dat.fu;

              //과정 종류
              dash.program=dat.program;
              dash.enterProgram= dat.enterProgram;

              //유입경로
              dash.path=dat.path;
              dash.enterPath= dat.enterPath;

              //문의유입사이트
              dash.url=dat.url;
              dash.enterUrl= dat.enterUrl;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
        }else{
          alert("시작일과 종료일은 동일한 연도이어야 합니다.");
        }
        
      },

    });

    onMounted(() => {
      // Mounted
      //올해 값을 가져오기
      var now = new Date();	
      dash.sdate = now.getFullYear() + "-01-01";
      dash.edate = now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);
      dash.doSearch();
    });

    return {  dash };
  },
};
</script>

<style lang="scss" scoped>
.table-row {
  text-align: center;
}

.border_b {
  border-bottom: 2px solid #000000 !important;
}

.fc_red-fw-bold {
  font-weight: 700;
  color: red;
}

.fw_bold-bg_blue {
  font-weight: 700;
  background-color: aliceblue;
}
</style>
